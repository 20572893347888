import ApparelList from "./ApparelList";
import Footer from "./Footer";
import useFetch from "./useFetch";

const Shop = () => {
  const { data: apparel, isLoading, error } = useFetch('https://pp4jeffrey.wd99p.com/apparels');

  return (
    <>
    <div className="shop py-10 px-10 overflow-hidden">
      {apparel && <ApparelList apparelItems={apparel}/>}
    </div>
    <Footer />
    </>
    
  );
}
 
export default Shop;