import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Cover from "../assets/images/braice-cover-1.jpg";
import Logo from "../assets/images/braice-logo.jpg";

const Hero = () => {
  return (
    <div className="hero w-full">
      <div className="w-full h-screen relative">
        <img
          src={Cover}
          alt="Cover"
          className="w-full h-full object-cover object-left hidden sm:block"
        />
        <img
          src={Logo}
          alt="Logo"
          className="w-full h-full object-cover sm:hidden"
        />
        <div className="absolute w-full h-full top-0 left-0 bg-gray-900/20"></div>
        <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center sm:items-end">
          <Link to="/shop">
            <button className="mb-10 sm:mr-10 md:mr-20 lg:mr-36">
              Shop Now
            </button>
          </Link>

          <button className="sm:mr-10 md:mr-20 lg:mr-36">Avail Services</button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
