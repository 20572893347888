import { useState } from "react";
import AddApparelForm from "./AddApparelForm";

const AddProduct = () => {
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [fileData, setFileData] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (fileData) {
      const formData = {
        name,
        category,
        description,
        price,
        image: fileData,
      };

      fetch("http://localhost:8080/apparels", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to add apparel");
          }
          console.log("New Apparel Added!");
          handleClear();
        })
        .catch((error) => {
          console.error("Error adding apparel:", error);
        });
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      console.log(reader);
      reader.onloadend = () => {
        setFileData(reader.result.split(",")[1]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClear = () => {
    setName("");
    setCategory("");
    setDescription("");
    setPrice("");
  };

  return (
    <div className="add-product py-28">
      <AddApparelForm
        name={name}
        category={category}
        description={description}
        price={price}
        setName={setName}
        setCategory={setCategory}
        setDescription={setDescription}
        setPrice={setPrice}
        setFileData={setFileData}
        handleFileChange={handleFileChange}
        handleClear={handleClear}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default AddProduct;
